<div class="content-wrapper" style="min-height: 906px">
  <section class="Leads_manage">
    <div class="bord">
      <div class="user-panel">
        <div class="pull-left info"style=" display: flex; margin-left: 20px; margin-top: 10px; margin-bottom: 10px;">
          <p style="font-weight: 900; font-size: 20px; margin-bottom: 1px; color: white;"> {{ name }}</p>
          <span style="color: white; margin-left: 20px;">{{ dateAndTime }}</span>
        </div>
      </div>
      <agm-map  class="sebm-google-map-container" [latitude]="lat" [longitude]="long" [zoom]="15" [mapTypeControl]="true" >
        <agm-marker [latitude]="lat" [longitude]="long"> </agm-marker>
      </agm-map>

        <!-- <agm-map class="sebm-google-map-container"
          style="width: 100%; height: 100%"
          [latitude]="lat"
          [longitude]="long"
          [zoom]="15"
          [mapTypeControl]="true"
        >
          <agm-marker
            [latitude]="lat"
            [longitude]="long"
          >
          </agm-marker>
        </agm-map> -->
     
    </div>
  </section>
</div>

<!-- <google-map
height="400px"
width="100%"
[center]="center"
[zoom]="zoom"
>
<map-marker [position]="center"></map-marker>
</google-map> -->
  import { Component, OnInit } from '@angular/core';
  import { ActivatedRoute } from '@angular/router';
  // import { AngularFireDatabase } from "angularfire2/database";
  import { AngularFireDatabase } from '@angular/fire/compat/database';
  // import { AngularFireDatabase } from '@angular/fire/compat/database';
  import { MainService } from 'src/app/MyService/main.service';


  @Component({
    selector: 'app-user-google-map-component',
    templateUrl: './user-google-map-component.component.html',
    styleUrls: ['./user-google-map-component.component.css']
  })
  export class UserGoogleMapComponentComponent implements OnInit {
    // center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
    zoom:15;
    lat: any;
    long: any;
    dateAndTime: any;
    userId: any;
    str: any;
    user: any;
    loc: any;
    name: any;
    phone: any;
    constructor(
      private db: AngularFireDatabase,
      private auth: MainService,
      private route: ActivatedRoute
    ) {
      this.userId = auth.getUserId();
    }

    ngOnInit() {
      this.userId = this.route.snapshot.paramMap.get("id");
      this.str = this.route.snapshot.paramMap.get("str");
      if(this.str === 'attendance'){
        this.loc = 'Attendance Location'
      }else{
        this.loc = 'Current Location'
      }
      this.userById(this.userId);
    }

    userTodayAttendace(userId) {
      console.log('today attendance');
      this.auth.getTodayAttendance(userId).subscribe(
        (data) => {
          this.dateAndTime = this.auth.toString(data.inTime);
          this.lat = data.inLat;
          this.long = data.inLong;
          // this.updateMapCenter();
        },
        (err) => {
          console.log(err);
        }
      );
    }

    currentLocation() {
      console.log('current location');
      this.db
        .object("/AttendanceLocationCoordinates/" + this.userId)
        .valueChanges()
        .subscribe((data) => {
          this.dateAndTime = data["dateAndTime"];
          this.lat = data["latitude"];
          this.long = data["longitude"];
          // this.updateMapCenter();
        },(err)=>{
          console.log(err);
        });
    }

    userById(userId) {
      this.auth.findUserById(userId).subscribe(
        (data) => {
          this.auth.stopLoader();
          this.user = data[0]; 
          console.log("User: " + JSON.stringify(this.user));
          const firstName = this.user.firstName;
          const lastName = this.user.lastName;
          this.name = `${firstName} ${lastName}`;
          this.phone = this.user.phone;
          if (this.str != null && this.str === 'attendance') {
            this.userTodayAttendace(this.userId);
          } else {
            this.currentLocation();
          }
        },
        (err) => {
          console.log(err);
          this.auth.stopLoader();
        }
      );
    }
    // private updateMapCenter() {
    //   if (this.lat !== null && this.long !== null) {
    //     this.center = { lat: this.lat, lng: this.long };
    //   }
    // }

  }


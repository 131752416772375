import { Injectable } from '@angular/core';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
// import { AngularFireMessaging } from '@angular/fire/compat/messaging';

import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService
{

  // currentMessage = new BehaviorSubject<any>(null);

  // constructor(private notification: AngularFireMessaging) { }

  // async requestPermission()
  // {
  //   try
  //   {
  //     const currentToken = await this.notification.getToken.pipe(
  //     ).toPromise();
  //     if (currentToken)
  //     {
  //       console.log('Now we have token');
  //       console.log("Fcm Token ",currentToken);
  //       localStorage.setItem('fcmToken', currentToken);
  //     }
  //     else
  //     {
  //       console.log("Error: Unable to get token");
  //     }
  //   }
  //   catch (error)
  //   {
  //     console.error("Error getting token:", error);
  //   }
  // }

  // receiveMessaging()
  // {
  //   this.notification.messages.subscribe((payload) =>
  //   {
  //     // console.log('New Notification is : ', payload);
  //     this.currentMessage.next(payload);
  //   });
  // }

}

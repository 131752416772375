import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError ,BehaviorSubject} from 'rxjs';
import baseUrl from '../services/helper';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';
import { catchError, tap } from 'rxjs/operators';
import { Feedback } from '../pages/Models/feedback';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
}) 
export class MainService {
  // private baseUrl = 'http://api.imconsole.in:8080';
  // private baseUrl = 'http://192.168.29.202:8080';
  // private baseUrl ='https://dev.virtualintelligence.co.in';
  // private baseUrl ='http://13.233.32.30:8282';
  // private baseUrl = 'https://api.gopropify.in';
  // private baseUrl ='http://148.66.133.154:8080';
  private baseUrl = 'https://api.virtualintelligence.co.in';
  // private baseUrl ='http://13.201.134.139:8080';
  private userId: any;
  private cloudinaryBaseUrl = 'https://api.cloudinary.com/v1_1/dzvsmmraz';
  private cloudinaryDeleteUrl = `${this.cloudinaryBaseUrl}/image/destroy`;
  private cloudinaryApiKey = '974981595445112';
  private cloudinaryApiSecret = '4URnjaut9IehzWDZZ8_AVH8pKoQ';
  handleError(error: any) {
    let errorMessage = 'An error occurred.';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = error.error.message;
    } else {
      // Server-side error
      errorMessage = error.error.message;
    }
    return throwError(errorMessage);
  }

  private isSidebarOpen = new BehaviorSubject<boolean>(true); // Default value is true (open)
  menuState$ = this.isSidebarOpen.asObservable();
  constructor(
    private _http: HttpClient,
    private ngxService: NgxUiLoaderService,
    private httpBackend: HttpBackend
  ) {}

  setUserId(userId: any) {
    this.userId = userId;
  }

  getUserId() {
    return this.userId;
  }

  public updateuser(user_id, userdata) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.put<any>(
      `${baseUrl}/update/by/user/id/${user_id}`,
      userdata
    );
  }



  toggleMenu() {
    this.isSidebarOpen.next(!this.isSidebarOpen.value);
  }

  
  public updateuserprofile(user_id, userdata: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.put<any>(`${baseUrl}/user/update/${user_id}`, userdata);
  }
  public updateRole(payload:any){
    var auth = localStorage.getItem('token');
    const httpOptions = {
      Headers : new HttpHeaders({
        'Content-Type':'application/json',
        Authorization:auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };
      return this._http.patch<any>(`${baseUrl}/update/user/role/by/user/id`,payload);
    }
  
   

  public leaveaprroval(user_id, userdata: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.patch<any>(
      `${baseUrl}/update/leave/status/by/user/id/${user_id}`,
      userdata
    );
  }
  public managerleaveapproval(manager_id, userdata: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.patch<any>(
      `${baseUrl}/update/leave/status/by/user/id/${manager_id}`,
      userdata
    );
  }
 
  public EditUser(user_id) {
    return this._http.get(`${baseUrl}/user/update/` + user_id);
  }

  public AllEmployeeProfile(pageNumber, pageSize) {
    return this._http.get(
      `${baseUrl}/user/active/all?page=${pageNumber}&size=${pageSize}`
    );
  }

  public AllEmployeeProfileList() {
    return this._http.get(`${baseUrl}/user/get/all/user/list`);
  }
  public AllEmployeeProfileListUsingRole(role,isEnabled=true) {
    return this._http.get(`${baseUrl}/user/get/all/user/list?role=${role}&isEnabled=${isEnabled}`);
  }


  public updateSalary(user_id) {
    return this._http.get(`${baseUrl}/update/by/user/id/${user_id}`, user_id);
  }

  public addUserprofile(user: any) {
    return this._http.post(`${baseUrl}/user/new/employee`, user);
  }

  public addsalaryprofile(user_id, userSalary) {
    return this._http.post(
      `${baseUrl}/save/salary/by/user/id/${user_id}`,
      userSalary
    );
  }

  public userrequestleave(user_id, userleave: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.post(
      `${baseUrl}/save/leave/by/user/id/${user_id}`,
      userleave
    );
  }

  deletesalary(id) {
    return this._http.delete(`${baseUrl}/delete/salary/by/user/id/${id}`);
  }

  public deleteemployeedetails(user_id: number, isEnabled: boolean) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
      responseType: 'text',
    };

    return this._http.patch<any>(
      `${baseUrl}/user/enable/${user_id}?isEnabled=${isEnabled}`,
      null
    );
  }

  public showuserbyId(user_id) {
    return this._http.get(`${baseUrl}/user/get/user/by/${user_id}`);
  }
  public getattendancewithuser1(user_id, pageNumber, pageSize) {
    return this._http.get(
      `${baseUrl}/get/user/attendance/${user_id}?page=${pageNumber}&size=${pageSize}`
    );
  }
  public taskpending(user_id) {
    return this._http.get(
      `${baseUrl}/get/total/pending/task/count/by/${user_id}`
    );
  }

  public newevent() {
    const url = `${baseUrl}/get/upcoming/events`;
    console.log('Fetching data from URL:', url);
    return this._http.get(url);
  }
  public Alltask(pageNumber, pageSize) {
    return this._http.get(
      `${baseUrl}/get/todays/task/of/all/user?page=${pageNumber}&size=${pageSize}`
    );
  }
  public gettask(user_id) {
    return this._http.get(`${baseUrl}/get/task/by/user/id/${user_id}`);
  }
  public addtask(user_id, user) {
    return this._http.post(`${baseUrl}/save/tasks/by/user/id/${user_id}`, user);
  }
  public addcomment(user_id, user: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.put<any>(
      `${baseUrl}/update/task/by/user/id/${user_id}`,
      user
    );
  }
  public showuserSalary(user_id) {
    return this._http.get(`${baseUrl}/get/salary/by/user/id/${user_id}`);
  }
  public saveLeave(user_id, user) {
    return this._http.post<any>(
      `${baseUrl}/save/multiple/leaves/${user_id}`,
      user
    );
  }
  public meetings(userId, user) {
    return this._http.post<any>(
      `${baseUrl}/assign/meeting/by/user/id/${userId}`,
      user
    );
  }

  public getPendingLeaveswelcome() {
    return this._http.get(`${baseUrl}/get/pending/leave/count/by/admin`);
  }

  public getPendingLeaves(pageNumber: number = 0, _pageSize) {
    return this._http.get(`${baseUrl}/get/all/pending/leaves`, {
      params: {
        page: pageNumber,
        size: _pageSize,
      },
    });
  }
  public allapprovedleave(userId) {
    return this._http.get(`${baseUrl}/get/all/approved/leaves`, userId);
  }
  public showCandidate() {
    return this._http.get(`${baseUrl}/get/all/proccessing/candidates`);
  }
  public deletecandidate(can_id) {
    return this._http.delete(`${baseUrl}/delete/candidate/by/${can_id}`);
  }
  public sendEmail(user_id, user: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    const url = `${baseUrl}/email/send/${user_id}`;
    return this._http.post<any>(url, user, httpOptions);
  }
  public addcandidate(user_id, user) {
    return this._http.post(
      `${baseUrl}/save/candidate/by/user/id/${user_id}`,
      user
    );
  }
  public updateCandidate(user_id, userdata) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.put<any>(
      `${baseUrl}/update/candidate/call/status/by/user/id/${user_id}`,
      userdata
    );
  }
  public calenderInvite(user_id, userdata): Observable<any> {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.post(
      `${baseUrl}/sendCalendarInvitation`,
      userdata,
      httpOptions
    );
  }

  public getupdatecandidate(candidate_id) {
    return this._http.get(
      `${baseUrl}/get/candidate/by/id/candidate/id/${candidate_id}`,
      candidate_id
    );
  }

  public deletemeeting(user_id) {
    return this._http.delete(
      `${baseUrl}/delete/meeting/by/creative/id/${user_id}`,
      user_id
    );
  }

  public userleavebyidwelcome(user_id) {
    return this._http.get(
      `${baseUrl}/get/leaves/of/user/by/userId/${user_id}`,
      user_id
    );
  }

  public taskbyid(user_id, userdata) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.put<any>(
      `${baseUrl}/update/task/status/by/user/id/${user_id}`,
      userdata
    );
  }

  public UpdateMeetingStatus(user_id, userdata) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.put<any>(
      `${baseUrl}/update/meeting/by/user/id/${user_id}`,
      userdata
    );
  }
  public updatePresence(
    meetingId: string,
    isUserPresent: boolean
  ): Observable<any> {
    const auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth}`, 
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.patch<any>(
      `${this.baseUrl}/update/presence/by/user/${meetingId}?isUserPresent=${isUserPresent}`,
      httpOptions
    );
  }

  datePipe = new DatePipe('en-US');
  toTimestamp(date: string) {
    if (date != null && date != '') {
      const formattedDate = this.datePipe.transform(date, 'MM-dd-yyyy');
      const myDate = new Date(formattedDate);
      return myDate.getTime();
    } else {
      return 0;
    }
  }
  toDateAndTimeTimestamp(date: string) {
    if (date != null && date != '') {
      const formattedDate = this.datePipe.transform(date, 'MM-dd-yyyy hh:mm a');
      const myDate = new Date(formattedDate);
      return myDate.getTime();
    } else {
      return 0;
    }
  }
  toString(timestamp) {
    if (timestamp > 0) {
      var date = new Date(timestamp);
      return this.datePipe.transform(date, 'dd-MM-yyyy hh:mm a');
    } else {
      return '';
    }
  }
  toTime(timestamp) {
    if (timestamp > 0) {
      var date = new Date(timestamp);
      return this.datePipe.transform(date, 'hh:mm a');
    } else {
      return '';
    }
  }

  saveEventsByAdmin(userId, object) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.post(
      `${baseUrl}/save/events/by/admin/user/${userId}`,
      object
    );
  }
  getEventsByAdmin(userId) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.get<any>(this.baseUrl + '/get/events/by/admin/' + userId);
  }
  deleteEvents(id: any) {
    return this._http.delete(`${baseUrl}/delete/calendar/event/by/${id}`);
  }
  getAllActiveUser() {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.get<any[]>(
      this.baseUrl + '/user/active/all',
      httpOptions
    );
  }
  startLoader() {
    this.ngxService.start();
  }
  stopLoader() {
    this.ngxService.stop();
  }
  getTodayAttendanceList(pageNumber, pageSize) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    const url = `${this.baseUrl}/get/today/all/attendance/list?page=${pageNumber}&size=${pageSize}`;
    return this._http.get<any[]>(url, httpOptions);
  }
  getUsers() {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.get<any[]>(this.baseUrl + '/user/active/all');
  }
  getTodayAttendance(userId) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    const todayUrl = `${this.baseUrl}/get/today/attendance/${userId}`;
    return this._http.get<any>(todayUrl);
  }
  findUserById(id) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.get<any>(this.baseUrl + '/user/find/user/by/user/' + id);
  }
  public weeklyAttendance(user_id, pageNumber, pageSize) {
    return this._http.get(
      `${baseUrl}/user/attendance/for/weekly/data/by/${user_id}?page=${pageNumber}&size=${pageSize}`,
      user_id
    );
  }
  public quarterAttendance(user_id, pageNumber, pageSize) {
    return this._http.get(
      `${baseUrl}/user/attendance/for/quarter/month/by/${user_id}?page=${pageNumber}&size=${pageSize}`,
      user_id
    );
  }
  public monthAttendance(user_id, pageNumber, pageSize) {
    return this._http.get(
      `${baseUrl}/user/attendance/for/monthly/data/by/${user_id}?page=${pageNumber}&size=${pageSize}`,
      user_id
    );
  }
  public allTotalSalary(userId) {
    return this._http.get(
      `${baseUrl}/get/all/time/total/salary/cycle/by/user/${userId}`,
      userId
    );
  }

  public updateEvent(user_id, userdata) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http
      .put<any>(`${baseUrl}/update/events/by/admin/${user_id}`, userdata)
      .pipe(
        catchError((error) => { 
          console.error('Error updating event:', error);
          return throwError('Something went wrong. Please try again later.');
        })
      );
  }

  SalesAllActiveUser() {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.get<any[]>(this.baseUrl + '/all/today/attendance');
  }

  SalesTodayAttendanceList() {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.get<any[]>(this.baseUrl + '/get/today/attendance');
  }

  generatePdf(htmlContent: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post<Blob>(
      this.baseUrl + '/generate-pdf',
      { htmlContent },
      {
        headers,
        responseType: 'blob' as 'json',
      }
    );
  }

  downloadpdf() {
    return this._http.get(`${baseUrl}/generate/active/user/pdf`, {
      responseType: 'blob',
    });
  }
  downloadExcel() {
    return this._http.get(`${baseUrl}/generate/active/user/excel`, {
      responseType: 'blob',
    });
  }
  public forgetPasswordAdmin(user_id, userdata: any,password:string) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.patch<any>(
      `${baseUrl}/forget/update/password/by/id/${user_id}?password=${password}`,
      userdata
    );
  }

  public monthbyApprovesleave(user_id) {
    return this._http.get(
      `${baseUrl}/get/current/month/approved/leave/count/by/admin`,
      user_id
    );
  }

  public sendOtpWithEmail(user: any) {
    return this._http.post(`${baseUrl}/forget/send-otp`, user);
  }
  public verifyotp(otp: string, email: string) {
    return this._http.post(
      `${baseUrl}/forget/verify-otp?otp=${otp}&email=${email}`,
      {}
    );
  }

  postUserData(user: any) {
    return this._http.post<any>(
      this.baseUrl + '/user/save/new/user/by/admin',
      user
    );
  }

  public changePassword(email: string, password: string) {
    return this._http.post(
      `${baseUrl}/forget/change-password?email=${email}&password=${password}`,
      {}
    );
  }

  public getRoles(): Observable<any> {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.get(this.baseUrl + '/user/get/roles', httpOptions);
  }

  public totaltask() {
    return this._http.get(`${baseUrl}/get/total/pending/task/count/by/admin`);
  }
  public totaltaskmonthly() {
    return this._http.get(
      `${baseUrl}/get/current/month/completed/task/count/by/admin`
    );
  }
  // public totalPendingtaskyearly() {
  //   return this._http.get(`${baseUrl}/get/total/pending/task/count/by/admin`);
  // }
  public totaluserPresent() {
    return this._http.get(`${baseUrl}/get/total/today/attendance/count`);
  }
  // public todayMeetingCount() {
  //   return this._http.get(`${baseUrl}/get/today/meeting/count`);
  // }
  private validKeys = [
    '4URnjaut9IehzWDZZ8_AVH8pKoQ',
    '974981595445112',
    'taskfiles',
    'announcement',
    'console',
    'profile',
  ];
  private cloudinaryUrl = `https://api.cloudinary.com/v1_1/${environment.cloudinary.cloudName}/image/upload`;

  uploadImage(vals: any): Observable<any> {
    const data = vals;
    const headers = new HttpHeaders();
    const httpClientWithoutInterceptor = new HttpClient(this.httpBackend);

    const uploadPreset = vals.get('upload_preset') as string;
    console.log('upload_preset:', uploadPreset);

    // Validate the API key and secret
    const apiKey = environment.cloudinary.apiKey;
    const apiSecret = environment.cloudinary.apiSecret;

    if (!apiKey || !apiSecret) {
      console.error('API Key or Secret is missing.');
      return throwError('API Key or Secret is missing.');
    }

    // Check if the upload preset is valid
    if (this.validKeys.includes(uploadPreset)) {
      return httpClientWithoutInterceptor.post(this.cloudinaryUrl, data, {
        headers,
      });
    } else {
      console.error('Invalid key for image. Received:', uploadPreset);
      return throwError('Invalid key for image.');
    }
  }
  // public interviewScheduled() {
  //   return this._http.get(`${baseUrl}/get/today/scheduled/interview/count`);
  // }

  public previousMonthPerformence() {
    return this._http.get(`${baseUrl}/previous/month/data/of/all/users`);
  }
  public findByDateCandidate(startDate: string, endDate: string) {
    return this._http.get(
      `${baseUrl}/get/candidate/by/${startDate}/${endDate}`
    );
  }

  public showDocs(user_id: string): Observable<any> {
    return this._http.get(`${baseUrl}/get/user/doc/by/${user_id}`);
  }

  public taskgraph() {
    return this._http.get(`${baseUrl}/monthly/task/statistics`);
  }
  public taskgraphUser(user_id: any) {
    return this._http.get(`${baseUrl}/monthly-statistics/by/${user_id}`);
  }

  public taskpietask(department: any) {
    return this._http.get(`${baseUrl}/department/${department}`, department);
  }
  public monthlyAttendance(userId: any) {
    return this._http.get(
      `${baseUrl}/get/user/monthly/attendance/count/by/${userId}`
    );
  }
  public report(user_id: any) {
    return this._http.get(
      `${baseUrl}/get/performace/of/employee/by/employee/id/${user_id}`
    );
  }

  public ratings(userId: string, empId: number, review: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.post(
      `${baseUrl}/save/new/performance/given/by/id/${userId}?empId=${empId}`,
      review,
      httpOptions
    );
  }
  public review(user_id: any) {
    return this._http.get(
      `${baseUrl}/monthly/performance/data/by/user/id/${user_id}`
    );
  }

  public getpastMedia() {
    return this._http.get(`${baseUrl}/all/data/of/social/media`);
  }
  public getLatestMedia() {
    return this._http.get(`${baseUrl}/latest-data/of/social/media`);
  }
  public overAll() {
    return this._http.get(`${baseUrl}/overall/performance/data/by/user`);
  }
  public salarypdf() {
    return this._http.get(`${baseUrl}/generate/get/user/salary/pdf`);
  }

  downloadsalarypdf(user_id: any) {
    return this._http.get(
      `${baseUrl}/generate/get/user/salary/pdf/${user_id}`,
      { responseType: 'blob' }
    );
  }

  downloadsalaryadminpdf() {
    return this._http.get(`${baseUrl}/generate/get/user/salary/pdf`, {
      responseType: 'blob',
    });
  }
  downloadtaskpdf(user_id: any, startDate?: any, endDate?: any) {
    let url = `${baseUrl}/generate/get/user/task/pdf/${user_id}`;
  
    // Append the date parameters only if they are provided
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`;
    }
  
    return this._http.get(url, {
      responseType: 'blob',
    });
  }
  

  public assignTask(user_id: any, user: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.post(
      `${baseUrl}/assign/task/to/employee/by/id?user_id=${user_id}`,
      user,
      httpOptions
    );
  }

  saveFeedBack(userId: number, feedbackData: Feedback): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.post(
      `${this.baseUrl}/save/new/feedback/by/user/id/${userId}`,
      feedbackData,
      httpOptions
    );
  }
  public savedocs(user_id: string, userdata: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT,PATCH',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.patch<any>(
      `${baseUrl}/user/save/user/doc/by/${user_id}`,
      userdata
    );
  }
  updateProfileImage(userId: string, fileUrl: string): Observable<any> {
    const auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: auth,
        'Content-Type': 'application/json', 
      }),
    };
    const encodedFileUrl = encodeURIComponent(fileUrl); 
    return this._http.patch<any>(
      `${this.baseUrl}/user/update/image/on/cloud/by/${userId}?file=${encodedFileUrl}`,
      httpOptions
    );
  }

  public isVerified(epmloyeeId: any, documentVerified: boolean) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.patch<any>(
      `${this.baseUrl}/verify/user/doc/by/${epmloyeeId}?isverified=${documentVerified}`,
      httpOptions
    );
  }

  public markAttendance(user_id) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.post(
      `${baseUrl}/save/user/attendance/by/system/${user_id}`,
      httpOptions
    );
  }
  public updateAttendance(user_id) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.put(
      `${baseUrl}/update/user/attendance/by/system/${user_id}`,
      httpOptions
    );
  }
  public updatefiles(userId: string, userdata: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT,PATCH',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.patch<any>(
      `${baseUrl}/update-documents/by/user/id/${userId}`,
      userdata
    );
  }
  public announcement(announcementData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.post(
      `${baseUrl}/save/new/announcement`,
      announcementData,
      httpOptions
    );
  }
  deleteImage(publicId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(
        `${this.cloudinaryApiKey}:${this.cloudinaryApiSecret}`
      )}`,
    });

    const body = new URLSearchParams();
    body.set('public_id', publicId);
    body.set('invalidate', 'true'); 

    return this._http.post(this.cloudinaryDeleteUrl, body.toString());
  }

  public getRoleById(user_id: any) {
    return this._http.get(`${baseUrl}/get/user/role/by/user/id/${user_id}`);
  }

  public customChat(announcementData: any): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.post(
      `${baseUrl}/custom-chat/save/new`,
      announcementData,
      httpOptions
    );
  }
  uploadFile(file: File, folderName: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this._http.post(`${this.baseUrl}/user/s3/upload/folder/docs?folderName=${folderName}`, formData);
  }
  

  public managerList(
    managerId: any,
    subChild: boolean,
    pageNumber = 0,
    pageSize
  ) {
    return this._http.get(
      `${baseUrl}/manager/get/all/members/attendance/by/id/${managerId}?subChild=${subChild}&page=${pageNumber}&size=${pageSize}`
    );
  }
  public tasklist(managerId: any, subChild: boolean) {
    return this._http.get(
      `${baseUrl}/manager/get/all/members/tasks/by/id/${managerId}?subChild=${subChild}`
    );
  }

  public managerAlllist(managerId: any, subChild: boolean) {
    return this._http.get(
      `${baseUrl}/manager/get/all/members/list/by/id/${managerId}?subChild=${subChild}`
    );
  }
  public getUserMonthAttendance(
    user_id: string,
    start_date: string,
    end_date: string
  ) {
    return this._http.get(
      `${baseUrl}/filter/all/attendance/by/mobile/${user_id}/${start_date}/${end_date}`
    );
  }
  public showMessage(adminId: any, pageNumber, pageSize, startDate, endDate) {
    let url = `${baseUrl}/custom-chat/get/by/admin/id/${adminId}?`;

    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    url += `page=${pageNumber}&`;

    url += `size=${pageSize}`;
    return this._http.get(url);
  }
  public showUserMessage(
    userId: any,
    startDate,
    endDate,
    pageNumber,
    pageSize
  ) {
    let url = `${baseUrl}/custom-chat/get/by/user/id/${userId}?`;

    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    url += `page=${pageNumber}&`;

    url += `size=${pageSize}`;
    return this._http.get(url);
  }
  public alltaskFilter(
    user_id: string,
    startDate: string,
    endDate: string,
    status,
    pageNumber,
    pageSize
  ) {
    return this._http.get(
      `${baseUrl}/get/task/by/${user_id}/${startDate}/${endDate}?taskStatus=${status}&page=${pageNumber}&size=${pageSize}`
    );
  }

  public task(
    user_id: any,
    status: any,
    pageNumber: any,
    pageSize: any,
    startDate?: any,
    endDate?: any
  ): Observable<any> {
    let url = `${baseUrl}/get/task/by/user/id/${user_id}?`;
    if (status) {
      url += `taskStatus=${status}&`;
    }
    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    url += `page=${pageNumber}&`;

    url += `size=${pageSize}`;

    return this._http.get(url);
  }

  public alltaskByStatus(
    user_id: any,
    status: any,
    pageNumber: any,
    pageSize: any,
    startDate?: any,
    endDate?: any
  ): Observable<any> {
    let url = `${baseUrl}/get/all/tasks?`;
    if (status) {
      url += `status=${status}&`;
    }
    if (user_id) {
      url += `userId=${user_id}&`;
    }
    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    url += `page=${pageNumber}&`;

    url += `size=${pageSize}`;
    return this._http.get(url);
  }
  
  public showuserFilter(
    user_id: string,
    startDate: string,
    endDate: string,
    pageNumber,
    pageSize
  ) {
    return this._http.get(
      `${baseUrl}/get/task/by/${user_id}/${startDate}/${endDate}?page=${pageNumber}&size=${pageSize}`
    );
  }
  public statusFilter(
    user_id: string,
    startDate: string,
    endDate: string,
    status: string,
    pageNumber,
    pageSize
  ) {
    return this._http.get(
      `${baseUrl}/get/task/by/${user_id}/${startDate}/${endDate}/${status}?page=${pageNumber}&size=${pageSize}`
    );
  }
  public showMeetings(
    status: string,
    hostId: string | null,
    userId: string,
    startDate: string,
    endDate: string,
    pageNumber: number,
    pageSize: number
  ) {
    let url = `${baseUrl}/get/all/meetings?`;
    if (status) {
      url += `status=${status}&`;
    }
    if (hostId) {
      url += `hostId=${hostId}&`;
    }
    if (userId) {
      url += `userId=${userId}&`;
    }
    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    url += `page=${pageNumber}&`;

    url += `size=${pageSize}`;
    return this._http.get(url);
  }

  public usergetmeetingbyid(
    user_id: string,
    pageNumber: number = 0,
    pageSize: number = 10,
    status: string = '',
    startDate?: any,
    endDate?: any
  ) {
    let url = `${baseUrl}/get/meeting/by/attendees/by/user/id/${user_id}?`;
    if (status) {
      url += `status=${status}&`;
    }
    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    url += `page=${pageNumber}&size=${pageSize}`;
    return this._http.get(url);
  }
  public showAllSalary(userId,isEnabled,pageNumber,pageSize) {
    let url=`${baseUrl}/get/all/salary?`;
    if(userId){
    url+=`userId=${userId}&`;
    }
    if(isEnabled){
      url+=`isEnabled=${isEnabled}&`
    }
    url += `page=${pageNumber}&size=${pageSize}`;
    return this._http.get(url);
  }
  managerGetAllLeaveOfUser(
    managerId: string,
    startDate?: any,
    endDate?: any,
    status?: any,
    managerStatus?: any,
    subChild: boolean = true,
    pageNumber: number = 0,
    pageSize: number = 10
  ) {
    let url = `${this.baseUrl}/manager/get/all/members/leaves/by/id/${managerId}?`;

    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    if (status) {
      url += `status=${status}&`;
    }
    if (managerStatus) {
      url += `managerStatus=${managerStatus}&`;
    }
    if (subChild) {
      url += `subChild=${subChild}&`;
    }
    url += `page=${pageNumber}&size=${pageSize}`;

    return this._http.get(url);
  }
  public  getAllLeave(
     userId: string,
    pageNumber: number = 0,
    pageSize: number = 10,
    startDate: string,
    endDate: string,
    status:string,
    approvedBy:number){
      let url = `${baseUrl}/get/all/leaves?`;
      if(userId){
        url+=`userId=${userId}&`
      }
    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    if (status) {
      url += `status=${status}&`;
    }
    if (approvedBy) {
      url += `approvedBy=${approvedBy}&`;
    }
    url += `page=${pageNumber}&`;

    url += `size=${pageSize}`;
    return this._http.get(url);
  }
  public userleavebyid(
    user_id: string,
    pageNumber: number = 0,
    pageSize: number = 10,
    startDate: string,
    endDate: string,
    status
    
  ) {
    let url = `${baseUrl}/get/leaves/of/user/by/userId/${user_id}?`;
    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    if (status) {
      url += `status=${status}&`;
    }
    url += `page=${pageNumber}&`;

    url += `size=${pageSize}`;
    return this._http.get(url);
  }
  public getUserAttendanceList(
    user_id,
    start_date,
    end_date,
    pageNumber,
    pageSize
  ) {
    let url = `${this.baseUrl}/get/user/attendance/${user_id}?`;
    if (start_date) {
      url += `startDate=${start_date}&`;
    }
    if (end_date) {
      url += `endDate=${end_date}&`;
    }
    url += `page=${pageNumber}&`;

    url += `size=${pageSize}`;

    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.get(url);
  }
  performance(userId, month): Observable<any> {
    let url = `${this.baseUrl}/monthly/performance/record/by/user/id/${userId}?`;

    if (month) {
      url += `month=${month}&`;
    }

    return this._http.get(url);
  }
  public dataPerformance(user_id) {
    return this._http.get(
      `${this.baseUrl}/monthly/performance/data/by/user/id/${user_id}`
    );
  }
  ////////////// Hiring Api's///////////////////

  // public getAllvacancy(isActive:boolean) {
  //   return this._http.get(`${baseUrl}/get/all/open/vacancies?isActive=${isActive}`);
  // }
  getAllvacancy(isActive): Observable<any> {
    return this._http.get(`${baseUrl}/get/all/open/vacancies?isActive=${isActive}`);
  }
  

 
  public saveNewVacancy(payload: any) {
    return this._http.post(`${baseUrl}/save/new/vacancies`, payload);
  }
  public getAllhiring(userId,vacancyId,isActiveVacancy,hiringAIStatus,status,startDate,designation,endDate,pageNumber,pageSize): Observable<any> {
    let url = `${baseUrl}/get/all/hirings?`;
    if (userId) {
      url += `userId=${userId}&`;
    }
    if (vacancyId) {
      url += `vacancyId= ${vacancyId}&`;
    }
    if (isActiveVacancy) {
      url += `isActiveVacancy=${isActiveVacancy}&`;
    }
    if (hiringAIStatus) {
      url += `hiringAIStatus=${hiringAIStatus}&`;
    }
    if (status) {
      url += `status=${status}&`;
    }
    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (designation) {
      url += `designation=${designation}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    url += `page=${pageNumber}&`;

    url += `size=${pageSize}`;
    return this._http.get(url);
  }
  public getPincode(pincode) {
    return this._http.get(
      `${baseUrl}/common-utility/get/all/data/by/${pincode}`
    );
  }

  public getHiringsByAssignedMember( startDate: any, endDate: any, status: string, page: number, size: number,userId: string) {
    let url = `${baseUrl}/get/hirings/by/assigned/member/${userId}?`;
    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if (endDate) {
      url += `endDate=${endDate}&`;
    }
    if (status) {
      url += `status=${status}&`;
    }
    url += `page=${page}&`;
    url += `size=${size}`;
  
    return this._http.get(url);
  }
  
  public saveHiringBySystem(user_id: any, payload: any) {
    return this._http.post(
      `${baseUrl}/save/hiring/by/user/id/${user_id}`,
      payload
    );
  }
  public uploadResume(resume: File) {
    const formData = new FormData();
    formData.append('file', resume);
    return this._http.post(`${baseUrl}/user/s3/upload/image`, formData);
  }
  public updateStatus(Id:any,payload:any)
  {
    return this._http.put(`${baseUrl}/update/hiring/by/id/${Id}`,payload)
  }
  public updateAssignUser(managerId,userId)
  {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.patch(`${baseUrl}/manager/assign/to/user/${managerId}/${userId}`,httpOptions);
  }
  public mothlyAttendanceCount(userId)
  {
    return this._http.get(`${baseUrl}/get/user/monthly/attendance/count/by/${userId}`);
  }
  public updatevacancy(Id: any, isActive: boolean) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
      }),
    };
    
    return this._http.patch(`${baseUrl}/update/vacancy/status/by/id/${Id}?isActive=${isActive}`,  httpOptions);
  }
  
  public updatevacancyformodel(Id:any,payload)
  {
    var auth = localStorage.getItem('token');
    const httpOptions={
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
          Authorization: auth,
          'cache-control': 'no-cache',
          'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
          'Access-Control-Allow-Origin': '*',
      }),
    };
    return this._http.patch(`${baseUrl}/update/vacancy/status/by/id/${Id}`,payload)
  }

  public getVacancyById(Id){
    return this._http.get(`${baseUrl}/get/vacancies/by/${Id}`)

  }
  public updateHiringStatus(hiringId,userId,payload){
    var auth = localStorage.getItem('token');
    const httpOptions={
      headers : new  HttpHeaders({
        'Content-Type':'application/json',
        Authorization:auth,
        'cache-control':'no-cache',
        'Access-Control-Allow-Methods':'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin':'*',
      })
    };
    return  this._http.patch(`${baseUrl}/assign/hiring/to/employee/${hiringId}/${userId}`,payload)
  }
  // email

  public getAllEmail(userId,startDate,endDate,pageNumber,pageSize): Observable<any> {
    let url = `${baseUrl}/email/data/get/all?`;
    if (userId) {
      url += `userId=${userId}&`;
    }
    if (startDate) {
      url += `startDate=${startDate}&`;
    }
    if(endDate){
      url += `endDate=${endDate}&`;
    }
    url +=`page=${pageNumber}&`;
    url +=`size=${pageSize}&`;
    return this._http.get(url);
  }

  public getEmailByid(Id){
    return this._http.get(`${baseUrl}/email/data/get/by/id/${Id}`)
  }
  
  public emailSendToUser(payload: any) {
    let url = `${baseUrl}/email/send/to/users/cc-bcc`;

    // Constructing the query parameters
    let params = new HttpParams()
        .set('toList', payload.toList.join(','))
        .set('subject', payload.subject)
        .set('body', payload.body);

    if (payload.cc && payload.cc.length > 0) {
        params = params.append('cc', payload.cc.join(','));
    }

    if (payload.bcc && payload.bcc.length > 0) {
        params = params.append('bcc', payload.bcc.join(','));
    }

    const formData = new FormData();
    payload.attachmentList.forEach((file: File) => {
        if (file && file.size > 0) {
            formData.append('attachmentList', file, file.name);
        }
    });

    return this._http.post(url, formData, { params: params });
}


  public getAllAssigmentbyUser(userId,status,startDate,endDate,pageNumber,pageSize){
  let url =`${baseUrl}/get/assignment/by/user/id/${userId}?`;
  if(status){
    url+= `status=${status}&`;
  }
  if(startDate){
    url+=`startDate=${startDate}&`;
  }
  if(endDate){
    url+=`endDate=${endDate}&`;
  }
  if(pageNumber){
    url+=`page=${pageNumber}&`;
  }
  if(pageSize){
    url+=`size=${pageSize}&`;
  }
  return this._http.get(url);
  }

 
  public savAssignment(user_id, user) {
    return this._http.post(
      `${baseUrl}/save/assignment/by/user/id/${user_id}`,
      user
    );
  }
  public getallAssignmentByUser(user_id) {
    return this._http.get(`${baseUrl}/get/assignment/by/user/id/${user_id}`);
  }
  public adminAssignment() {
    return this._http.get(`${baseUrl}/get/all/pending/assignments`);
  }
public getAssignmentById(Id:any){
return this._http.get(`${baseUrl}/get/assignment/by/id/${Id}`);
}
public deleteSubTask(subtaskId,assignmentId){
  return this._http.delete(`${baseUrl}/delete/subtask/by/${subtaskId}/${assignmentId}`)
}
  public getAllAssignmentDetails(assignmentId,isPriority,status,assignToId,pageNumber,pageSize){
    let url= `${baseUrl}/get/subtask/by/assignment/id${assignmentId}?`;
    if(isPriority){
      url +=`isPriority=${isPriority}&`;
    }
    if(status){
      url +=`status=${status}&`;
    }
    if(assignToId){
      url +=`assignToId=${assignToId}&`;
    }
    url+=`page=${pageNumber}&`;
    if(pageSize){
      url+= `size=${pageSize}`;
    }
    return this._http.get(url) ;
  }
  public deleteassignment(user_id) {
    return this._http.delete(
      `${baseUrl}/delete/assignment/by/assignment/id/${user_id}`,
      user_id
    );
  }
  public updatebyuserid(user_id, user: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.put<any>(
      `${baseUrl}/update/assignment/status/by/user/id/${user_id}`,
      user
    );
  }

  public updateAssignmentbyadmin(user_id, user: any) {
    var auth = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: auth,
        'cache-control': 'no-cache',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this._http.put<any>(
      `${baseUrl}/update/assignment/by/admin/id/${user_id}`,
      user
    );
  }

 
  public getSubtaskByAssignment(assignmentId, payload: any): Observable<any> {
    return this._http.post(`${this.baseUrl}/add/subtask/for/assignment/${assignmentId}`, payload);
  }
  
  public consolidatedAssignment(){
    return this._http.get(`${baseUrl}/get/consolidated/assignment-data`);
  }
  public updateNotes(Id: string, payload: { notes: string[] }) {
    return this._http.put(`${baseUrl}/update/assignment/by/id/${Id}`, payload);
  }
  public updateDescription(Id: string, payload :{ description: string }) {
    return this._http.put(`${baseUrl}/update/assignment/by/id/${Id}`, payload);
  }
  public updateProject(Id,projectupdate){
    return this._http.put(`${baseUrl}/update/assignment/by/id/${Id}`,projectupdate);
  }
  public addStage(assignmentId: any, stage: any) {
    const params = new URLSearchParams({
          createdDate: '0', 
          updatedDate: '0', 
          completionTime: stage.completionTime || 'string',
        stageId: stage.stageId?.toString() || '0',
        stageName: stage.stageName || 'string',
        status: stage.status || 'INITIALIZED',
        assignmentsId: assignmentId.toString(),
        assignmentSubject: 'string' 
    });

    return this._http.post(`${baseUrl}/save/stage/by/assignment/id/${assignmentId}?${params.toString()}`, {});
    }
    public getAllStageById(assignmentId: any, pageNumber: number, pageSize: number) {
      const params = new URLSearchParams({
        assignmentId: assignmentId.toString(),
        page: pageNumber.toString(),
        size: pageSize.toString()
      });

      return this._http.get(`${baseUrl}/get/all/assignment/stages?${params.toString()}`);
    }

 public addStoryBySubtaskId(subtaskId,assignmentId,payload){
  return this._http.put(`${baseUrl}/update/subtask/by/subtask/id/and/assignment/id/${assignmentId}?assignmentId=${subtaskId}`,payload)
 }
 public updateStagesOfAssignment(assignmentId,stageId,payload){
  return this._http.patch(`${baseUrl}/update/stage/by/assignment/id/${assignmentId}/${stageId}`,payload)
 }
 public getStageById(assignmentId,stageId,pageNumber,pageSize){
  return this._http.get(`${baseUrl}/get/all/assignment/stages?assignmentId=${assignmentId}&stageId=${stageId}&page=${pageNumber}&size=${pageSize}`)
 }
 public getAllSubtask(subtaskId){
  return this._http.get(`${baseUrl}/get/subtask/by/id/${subtaskId}`)
 }
 public addDeduction(userId,payload){
  return this._http.patch(`${baseUrl}/deduct/salary/by/user/id/${userId}`,payload)
 }
 public topPerformer(){
  return this._http.get(`${baseUrl}/performance/record/get/all`)
 }
 public getAllDepartment(){
  return this._http.get(`${baseUrl}/user/get/all/department/list`)
 }
 public department(department:string){
  return this._http.get(`${baseUrl}/department/${department}`)
 }
}


<!-- <ul class="nav nav-tabs">
  <li class="nav-item" (click)="showTab('profile')">
    <a class="nav-link" [class.active]="activeTab === 'profile'">My Profile</a>
  </li> -->
  <!-- <li class="nav-item" (click)="showTab('documents')">
    <a class="nav-link" [class.active]="activeTab === 'documents'">Documents</a>
  </li> -->
  <!-- <li class="nav-item" (click)="showTab('feedback')">
    <a class="nav-link" [class.active]="activeTab === 'feedback'">Feedback & Query</a>
  </li>
  <li class="nav-item" (click)="showTab('benifits')">
    <a class="nav-link" [class.active]="activeTab === 'benifits'">Benifits & Pay</a>
  </li>
  <li class="nav-item" (click)="showTab('password')">
    <a class="nav-link" [class.active]="activeTab === 'password'">Change Password</a>
  </li> -->
<!-- </ul> -->

<!-- <div class="container">
  <app-user-document *ngIf="activeTab === 'documents'"></app-user-document>
  <app-feedback *ngIf="activeTab === 'feedback'"></app-feedback>
  <app-forget-password-user *ngIf="activeTab === 'password'"></app-forget-password-user>
</div> -->
<div>
<h4>My Profile</h4>
<mat-card *ngIf="user" class="profile-card">
  <div class="row" style="padding: 0 92px;">
    <div class="col-md-12 position-relative first-div" style="background-image: url('assets/Cover.png');width: 100%;">
      <div class="profile-image">
        <img 
          [src]="user?.profileImage || 'assets/12.png'" 
          alt="Profile Picture" 
          style="width: 100px; height: 102px; border-radius: 50%; position: absolute; background-color: rgba(173, 168, 214, 1); top: 58px; left: 13px; " 
        />
        <img class="edit-icon" src="assets/Icon.png" alt="edit icon" (click)="onEditClick()" style="cursor: pointer;"/>
      </div>
      <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;" />

      <!-- <div class="delete-icon" (click)="onDeleteImage(user.profileImage)" style="cursor: pointer;">
        <img src="assets/deleteicon.png" alt="Delete Icon" />
      </div> -->
      
      
    </div>

    <!-- Second Div with Employee Details -->
    <div class="col-md-12 container2 second-div">
      <div class="scrollable-content">
      <h2 class="text-center">Employee Details</h2>
      <div class="row" style="    padding: 42px;  margin-top: -46px;">
        <!-- Personal Information -->
        <div class="col-md-12 details-section">
          <div class="custom-head">
            <h3>Personal Information</h3>
          </div>
          <div class="row" style="border: 1.78px solid #857e7e; border-radius: 0px 0px 8px 8px; background: #161515; padding: 28px;">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Employee ID :</div>
                  <div class="value">{{ user.id }}</div>
                </div>
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Full Name :</div>
                  <div class="value">{{ user.firstName }} {{ user.lastName }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Date of Birth :</div>
                  <div class="value">{{ user.dob | date:'dd-MMM-yyyy' }}</div>
                </div>
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Gender :</div>
                  <div class="value">{{ user.gender }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Designation :</div>
                  <div class="value">{{ user.designation }}</div>
                </div>
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Status:</div>
                  <div class="value">
                    <button *ngIf="user.active" style="background-color: green; border: none; border-radius: 50%; width: 24px; height: 24px;">
                      <span style="color: white; font-size: 16px;">✓</span>
                    </button>
                    <button *ngIf="!user.active" style="background-color: red; border: none; border-radius: 50%; width: 24px; height: 24px;">
                      <span style="color: white; font-size: 16px;">✕</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Contact Information & Address -->
        <div class="col-md-12 details-section">
          <div class="custom-head">
            <h3>Contact Information & Address</h3>
          </div>
          <div class="row" style="border: 1.78px solid #857e7e; border-radius: 0px 0px 8px 8px; background: #161515; padding: 28px;">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Phone Number :</div>
                  <div class="value">{{ user.phone }}</div>
                </div>
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Alternate Number :</div>
                  <div class="value">{{ user.alternateNumber }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Email :</div>
                  <div class="value">{{ user.email }}</div>
                </div>
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Current Address :</div>
                  <div class="value">{{ user.currentAddress }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Permanent Address :</div>
                  <div class="value">{{ user.permanentAddress }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Employment Details -->
        <div class="col-md-12 details-section">
          <div class="custom-head">
            <h3>Employment Details</h3>
          </div>
          <div class="row" style="border: 1.78px solid #857e7e; border-radius: 0px 0px 8px 8px; background: #161515; padding: 28px;">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Joining Date :</div>
                  <div class="value">{{ user.joiningDate | date:'dd-MMM-yyyy' }}</div>
                </div>
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Job Title :</div>
                  <div class="value">{{ user.jobTitle }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Department :</div>
                  <div class="value">{{ user.department }}</div>
                </div>
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Work Location :</div>
                  <div class="value">{{ user.workLocation }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Department Head :</div>
                  <div class="value">{{ user.departmentHead }}</div>
                </div>
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Work Email :</div>
                  <div class="value">{{ user.email }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</mat-card>
</div>
<div *ngIf="activeTab === 'documents'">
<h4>Documents</h4>
<div class="container">
  
  <div class="box" *ngFor="let user of urls.documents">
    <div class="card-header">{{user.category}}</div>
    <button mat-icon-button [matMenuTriggerFor]="menu" style="float:right" (click)="downloadImage(user.imageUrl, getImageAlt(user.imageUrl))">
      <mat-icon class="more-icon" style="margin-top: -52px;
      color: #7f7979;
      margin-left: -65px;">download</mat-icon>
    </button>
    <div class="card card-body">
      <img class="card-image" [src]="user.imageUrl" alt="{{user.category}}">
    </div>
  </div>

</div>
</div>
<div *ngIf="activeTab === 'feedback'">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <h4>Feedback</h4>
        <form >
          <label for="section">Section:</label>
          <select id="section" name="section"  required>
            <option value="" disabled selected>Select a Section</option>
            <option value="task">Task</option>
            <option value="attendance">Attendance</option>
            <option value="assignment">Assignment</option>
            <option value="salary">Salary</option>
            <option value="review">Review</option>
            <option value="uploadDocument">Upload Document</option>
            <option value="other">Other</option>
          </select>
          <label for="feedback">Feedback:</label>
          <textarea id="feedback" name="feedback"  required rows="10"></textarea>
          <button  mat-raised-button color="accent" type="submit" > Submit</button>
        </form>
        
      </div>
      <div class="col-md-5">
        <h4>Recent Feedback</h4>
        <div class="row">
          <div class="col-md-6"> Management Issue</div>
          <div class="col-md-6">Fri, 23 April 2024</div>
         
        <div class="issue">
          <h2>Fri, 23 April 2024</h2>
          <p>To enhance our work environment, I suggest:</p>
          <ul>
              <li>Improving communication</li>
              <li>Establishing structured feedback mechanisms</li>
              <li>Offering more professional development opportunities</li>
              <li>Promoting a better work-life balance</li>
          </ul>
      </div>
      <div class="issue">
          <h2>Wed, 3 Feb 2024</h2>
          <p>To improve our workplace, please consider:</p>
          <ul>
              <li>Enhancing team collaboration</li>
              <li>Providing clearer project guidelines</li>
              <li>Ensuring equitable resource distribution</li>
          </ul>
      </div>
    </div>
      </div>
    </div>
    
</div>
</div>
<div *ngIf="activeTab === 'benifits'">
  <h1>Benefits & Pay</h1>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Basic Salary</th>
                <th>HRA</th>
                <th>Medical Fund</th>
                <th>Bonus</th>
                <th>Incentive</th>
                <th>Convenience</th>
                <th>Total Salary</th>
                <th>Pay Salary</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>211</td>
                <td>Aman Verma</td>
                <td>27,000</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-subtask',
  templateUrl: './update-subtask.component.html',
  styleUrls: ['./update-subtask.component.css']
})
export class UpdateSubtaskComponent implements OnInit {
  assignedById = localStorage.getItem('id');
  isSubmitting = false;
  employees: any[] = [];
  files: File[] = [];

  public updateUser = {
    subtaskName: '',
    subtaskDescription: '',
    taskDeadline: '',
    fileUrl: '',
    subtaskStatus: '',
    priorityLevel: '',
  };

  constructor(
    private snack: MatSnackBar, 
    private mainService: MainService, 
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { projectId: string, subtask: string }
  ) {}
 
  ngOnInit(): void {
    // Load employees
    this.mainService.managerAlllist(this.assignedById, true).subscribe((res: any) => {
      this.employees = res;
    });

    // Load subtask details
    this.mainService.getAllSubtask(this.data.subtask).subscribe((res: any) => {
      this.updateUser = {
        ...this.updateUser,
        subtaskName: res.name,
        subtaskDescription: res.description,
        taskDeadline: new Date(res.deadline).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }),  
        fileUrl: res.fileUrl,
        subtaskStatus: res.status,
        priorityLevel: res.priorityLevel,
      };
    });
  }

  onSelect(event) {
    const maxFileSize = 5 * 1024 * 1024;
    if (event.addedFiles.length > 1) {
      Swal.fire('Error', 'Only one file can be uploaded at a time', 'error');
      return;
    }
    const fileToAdd = event.addedFiles[0];
    if (fileToAdd.size > maxFileSize) {
      Swal.fire('Error', 'File size exceeds 5 MB limit.', 'error');
      return;
    }
    if (fileToAdd.type === 'application/pdf') {
      Swal.fire('Error', 'PDF files are not allowed.', 'error');
      return;
    }
    this.files = [fileToAdd];
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  formSubmit() {
    if (this.files.length === 0 && !this.updateUser.subtaskDescription) {
      Swal.fire('Error', 'Please upload a file or provide a task description.', 'error');
      return;
    }
    if (this.updateUser.taskDeadline) {
      this.updateUser.taskDeadline = new Date(this.updateUser.taskDeadline).getTime().toString();
    }

    this.isSubmitting = true;
    if (this.files.length > 0) {
      this.uploadFiles();
    } else {
      this.updateTask();
    }
  }

  uploadFiles() {
    const formData = new FormData();
    formData.append('file', this.files[0]);
    this.mainService.uploadImage(formData).subscribe(
      (res: any) => {
        this.updateUser.fileUrl = res.secure_url;
        this.updateTask();
      },
      (error) => {
        Swal.fire('Error', 'Failed to upload file.', 'error');
        this.isSubmitting = false;
      }
    );
  }

  updateTask() {
    this.mainService.addStoryBySubtaskId(this.data.projectId ,this.data.subtask, this.updateUser).subscribe(
      (res) => {
        Swal.fire('Success', 'Task updated successfully.', 'success');
        this.router.navigate(['/manager-dashboard/task']);
        this.isSubmitting = false;
      },
      (error) => {
        Swal.fire('Error', 'Failed to update task.', 'error');
        this.isSubmitting = false;
      }
    );
  }
}

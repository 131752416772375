<header class="navbar-container">
  <nav class="navbar">
    <!-- Left Section: Logo -->
    <div class="navbar-left">
      <ion-icon 
        name="{{ isMenuOpen ? 'close' : 'menu' }}" 
        class="menu-icon" 
        (click)="toggleMenu()">
      </ion-icon>
      <img class="logo" src="../../../assets/HR CONSOLE HORIZONTAL copy.png" alt="HR Console" />
    </div>

    <!-- Center Section: Links -->
    <ul class="navbar-links"  *ngIf="!isLoggedIn">
      <li><a href="#" class="navbar-link">Features</a></li>
      <li><a href="#" class="navbar-link">Visit Website</a></li>
      <li><a href="#" class="navbar-link">Support</a></li>
    </ul>

    <!-- Right Section: Button or User Profile -->
    <div class="navbar-right">
      <button 
        *ngIf="!isLoggedIn" 
        class="get-yours-now-btn"   routerLink="/login">
        Get Yours Now
      </button>
      <div *ngIf="isLoggedIn" class="user-profile">
        <img 
          [src]="user.profileImage ?? '../../../assets/default-profile.png'" 
          alt="Profile" 
          class="profile-image" 
        />
        <span>
          <a 
            [routerLink]="
              userRole === 'ADMIN' ? '/admin/admin-profile' :
              userRole === 'MANAGER' ? '/manager-dashboard/manager-profile' :
              userRole === 'USER' ? '/user-dashboard/user-profile' : null
            ">
            {{ user.firstName }}
          </a>
        </span>
      </div>
    </div>
  </nav>
</header>

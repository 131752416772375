import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import dayGridPlugin from '@fullcalendar/daygrid';
// import * as Sentry from "@sentry/angular";
import interactionPlugin from '@fullcalendar/interaction';
// import {registerLicense} from '@syncfusion/ej2-angular-calendars';

// registerLicense("");
// Sentry.init({
//   dsn: "https://215c72dcf1cc7e9534590f4e675c0556@o4508319798001664.ingest.us.sentry.io/4508319808552960",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//   ],

//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  // Load the FullCalendar plugins
dayGridPlugin;
interactionPlugin;

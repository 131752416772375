import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-add-task',
  templateUrl: './admin-add-task.component.html',
  styleUrls: ['./admin-add-task.component.css', '/../../../shared/all-style/add-meeting-style.component.css']
})
export class AdminAddTaskComponent implements OnInit {
  id = localStorage.getItem('id');
  employee: string = '';
  isSubmitting = false;
  selectedFiles: File[] = [];
  user = {
    date: '',
    time: '',
    subject: '',
    fileurl: [],
    deadDate: '',
    deadTime: '',
    deadLine: '',
    
  };
  data: any;

  constructor(
    private snack: MatSnackBar,
    private mainService: MainService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mainService.AllEmployeeProfileList().subscribe(
      (res: any[]) => {
        this.data = res;
      },
      (error) => {
        console.error('Error fetching employee list:', error);
      }
    );
  }

  onSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const maxFileSize = 5 * 1024 * 1024; // 300 KB limit
      Array.from(input.files).forEach((file) => {
        if (file.size > maxFileSize) {
          Swal.fire('Error', `File "${file.name}" exceeds 5 MB limit.`, 'error');
        } else if (file.type === 'application/pdf') {
          Swal.fire('Error', `File "${file.name}" is a PDF and cannot be uploaded.`, 'error');
        } else {
          this.selectedFiles.push(file);
        }
      });
    }
  }

  removeFile(index: number): void {
    this.selectedFiles.splice(index, 1);
  }

  formSubmit(): void {
    if (!this.user.subject && this.selectedFiles.length === 0) {
      Swal.fire('Error', 'Please provide a task description or upload files.', 'error');
      return;
    }

    if (!this.user.deadDate || !this.user.deadTime) {
      Swal.fire('Error', 'Please provide both deadline date and time.', 'error');
      return;
    }

    this.isSubmitting = true;
    const deadlineTimestamp = new Date(`${this.user.deadDate}T${this.user.deadTime}`).getTime();
    this.user.deadLine = deadlineTimestamp.toString();  
    this.uploadFiles(deadlineTimestamp);
  }

  uploadFiles(deadlineTimestamp: number): void {
    const documentsArray: string[] = [];
    let processedFiles = 0;

    if (this.selectedFiles.length === 0) {
      this.addOrAssignTask();
      return;
    }

    this.selectedFiles.forEach((file) => {
      const folderName = 'taskDocs';
      const data = new FormData();
      data.append('file', file);

      this.mainService.uploadFile(file, folderName).subscribe(
        (res: any) => {
          documentsArray.push(res.message);

          if (++processedFiles === this.selectedFiles.length) {
            this.user.fileurl = [...documentsArray];
            this.addOrAssignTask();
          }
        },
        (error) => {
          Swal.fire('Error', 'File upload failed. Please try again.', 'error');
          this.isSubmitting = false;
        }
      );
    });
  }

  addOrAssignTask(): void {
    const taskData = {
      ...this.user,
      fileurl: this.user.fileurl,
    };

    const userId = this.employee || this.id;

    if (this.employee) {
      this.mainService.assignTask(userId, taskData).subscribe(
        () => {
          Swal.fire('Success', 'Task assigned successfully.', 'success');
          this.router.navigate(['/admin/showuser-task']);
          this.isSubmitting = false;
        },
        (error) => {
          Swal.fire('Error', 'Failed to assign task. Please try again.', 'error');
          this.isSubmitting = false;
        }
      );
    } else {
      this.mainService.addtask(userId, taskData).subscribe(
        () => {
          Swal.fire('Success', 'Task added successfully.', 'success');
          this.router.navigate(['/admin/showuser-task']);
          this.isSubmitting = false;
        },
        (error) => {
          Swal.fire('Error', 'Failed to add task. Please try again.', 'error');
          this.isSubmitting = false;
        }
      );
    }
  }
}
